<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/raa/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Israel and the Occupied Palestinian Territories</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <p style="text-align: center"><strong>Important Update</strong></p>
      <p style="text-align: center">
        <strong
          >Israel Effective 9th October 2024</strong
        >
      </p>
      <p style="background: white; margin: 0cm 0cm 8.25pt">
        Effective from 09 October 2024, we have applied an embargo on 
        the sales of all new travel insurance coverage to Israel, due 
        to the deteriorating security and escalation of the conflict 
        in the region.
      </p>
      <p style="background: white; margin: 0cm 0cm 8.25pt">
        Whilst the Australian Government’s travel advice is to reconsider 
        your need to travel to Israel, we have assessed this against our 
        insurance risk appetite and whether our travel insurance would 
        provide adequate coverage under the circumstances, noting the 
        specific terms and exclusions relevant to the current conflict. 
        We have made the decision to cease offering cover.
      </p>
      <p><strong>As a result, no new travel insurance cover for travel to 
        Israel can be purchased and Israel cannot be added as a destination 
        to existing policies until further notice from 09 October 2024.
      </strong></p>
      <p><strong>Travel policy coverage to Israel</strong></p>
      <p>
        Acts of war as well as a traveller’s choice to travel to a 
        destination where there is an Australian Government ‘Do Not Travel’ 
        advisory in place are generally not covered by our travel insurance 
        policies. Refer to your policy documentation to review the specific 
        terms and exclusions they contain.
      </p>
      <p><strong>Customers due to travel to Israel</strong></p>
      <p>
        If you have a travel insurance policy and are traveling to Israel in 
        the near future, you will continue to be covered to the extent of the 
        terms of your policy, unless the Australian Government changes its 
        Smart Traveller advice for Israel to ‘Do Not Travel’, in which case 
        further policy coverage limitations and exclusions would apply.
      </p>
      <p>
        If your trip is affected and you wish to make a claim for our 
        consideration, you can do so.
      </p>
      <p><strong>Customers already in Israel</strong></p>
      <p>
        If you are already in Israel you will continue to be covered to the 
        extent of the terms of your policy but in the event of a change in 
        travel advice to ‘Do Not Travel’ we advise you to contact our 24 hour 
        Emergency Assistance Team for any queries and assistance in departing 
        Israel as soon as possible – refer to your policy document for contact 
        details.
      </p>
      <p>
        Limits, conditions and exclusions apply under your policy. Please 
        refer to the Product Disclosure Statement and your Certificate of 
        Insurance for full details.
      </p>

      <p style="text-align: center"><strong>Important Update</strong></p>
      <p style="text-align: center">
        <strong
          >Israel and the Occupied Palestinian Territories – what you need to
          know</strong
        >
      </p>
      <p style="background: white; margin: 0cm 0cm 8.25pt">
        On 7 October, Israel declared a state of war in response to a reported
        attack by Hamas. Thousands have been killed or injured, and hostages
        have been taken. At the date of publication of this advice, the military
        conflict remains ongoing. The situation is highly challenging and
        rapidly changing and Australians in the zone are strongly encouraged to
        leave.
      </p>
      <p style="background: white; margin: 0cm 0cm 8.25pt">
        We understand that customers have many questions and concerns about
        their travel insurance coverage whether they are currently in Israel or
        planning to travel.
      </p>
      <p>
        The following Frequently Asked Questions (FAQs) have been developed to
        assist in providing clarity on situations you may find yourself in, and
        how those situations relate to your travel insurance coverage.
      </p>
      <p>
        All coverage is assessed on the standard terms and conditions of your
        policy.
      </p>
      <p>
        Please be aware that there are general exclusions within your policy
        with respect to any claims arising out of “Acts of War” and regarding
        travel to areas subject to Do Not Travel status.
      </p>
      <p><strong>What is the current status?</strong></p>
      <p>As of 21 October 2023, the DFAT advice is:</p>
      <ul>
        <li>
          <strong>Do Not Travel</strong>&nbsp;to the West Bank (excluding East
          Jerusalem) and to the border areas with Lebanon due to the volatile
          security situation; and
        </li>
        <li>
          <strong>Reconsider your need to travel</strong>&nbsp;to Israel and the
          Occupied Palestinian Territories overall.
        </li>
      </ul>
      <p><strong>I have started my trip</strong></p>
      <p>
        <strong
          >What coverage do I have if I am in a conflict affected area?</strong
        >
      </p>
      <p>For more information on coverage see the table below.</p>
      <div class="table-responsive">
        <table class="table custom-table-responsive">
          <tbody>
            <tr >
              <td              >
                
              </td>
              <td               
              >
                
                  <strong>Policies Purchased from 7 October 2023</strong>
                
              </td>
              <td                
              >
                
                  <strong>Policies purchased prior to 7 October 2023</strong>
                
              </td>
            </tr>
            <tr >
              <td                
              >                
                  <strong
                    >Customer travelling within Do Not Travel (DNT) areas</strong
                  >
               
              </td>
              <td                   
              >
                
                  Polices do not cover travel to areas that are subject to Do Not
                  Travel warnings.<strong
                    >Therefore any injury, loss or claim relating to
                    acustomer’stravel to Gaza or the West Bank at this time while
                    the DNT warning applies</strong
                  > will not be covered.
                
              </td>
              <td                
              >
                
                  For Customers already located in DNT areas who purchased policies
                  prior to 7 October 2023, individual cases will need to be
                  assessed on a case by case basis. Please contact
                  <a href="tel:+61 2 8055 1698">+61 2 8055 1698</a>.
                
              </td>
            </tr>
            <tr >
              <td 
              >
                
                  <strong
                    >Customers travelling within Israel (excluding DNT
                    areas)</strong
                  >
                
              </td>
              <td
                
              >
                
                  Customers who are currently in Israel are encouraged to contact
                  our emergency assistance team to discuss options in relation to
                  returning to Australia.
               <br>
                  Individual claims will need to be assessed on a case by case
                  basis. Please refer any such instances to
                  <a href="tel:+61 2 8055 1698">+61 2 8055 1698</a>.
                
              </td>
              <td
                
              >
                
                  Customers who are currently in Israel are encouraged to contact
                  our emergency assistance team to discuss options in relation to
                  returning to Australia.   
                  <br>
                  Individual claims will need to be assessed on a case by case
                  basis. Please refer any such instances to
                  <a href="tel:+61 2 8055 1698">+61 2 8055 1698</a>.
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     
      
      <p style="margin-top: 0cm">
        <strong
          >If I choose to remain in Israel will I continue to be
          covered?</strong
        >
      </p>
      <p style="margin-top: 0cm">
        If you purchased a policy prior to 7 October and have chosen to remain
        in Israel, you should understand that any coverage you have does not
        extend to any claims arising from or relating to any act of war. If you
        are injured you may not be covered. In addition, as the situation is
        evolving there may be further extensions of the DNT warnings to wider
        areas of Israel. For any questions you have in relation to your existing
        cover please contact <a href="tel:+61 2 8055 1698">+61 2 8055 1698</a>.
      </p>
      <p style="margin-top: 0cm">
        <strong
          >I have decided to stay in Israel but my policy is about to expire.
          Can I extend my cover?</strong
        >
      </p>
      <p style="margin-top: 0cm">
        If you are requiring an extension of existing cover and you are situated
        in Israel please contact
        <a href="tel:+61 2 8055 1698">+61 2 8055 1698</a> to discuss your
        circumstances.
      </p>
      <p>
        <strong
          >Currently I am in another Middle Eastern country and would like to
          interrupt my trip due to events in Israel. Would I be able to
          claim?</strong
        >
      </p>
      <p>
        At first instance we recommend that customers who wish to interrupt or
        reschedule travel arrangements contact their airline or tour operator as
        they may have available options relating to cancellation of trips. Many
        travel suppliers are waiving change fees and/or offering refunds or
        credits for future travel when traveling to Israel.
      </p>
      <p style="background: white; margin: 0cm 0cm 0.0001pt">
        If your trip is affected and you wish to submit a claim for
        consideration you can do so via:
      </p>
      <p style="background: white; margin: 0cm 0cm 0.0001pt">
        Web:&nbsp;<a
          rel="noopener noreferrer"
          target="_blank"
          routerLink="/how-to-claim"
          >how-to-claim</a
        >
      </p>
      <p style="background: white; margin: 0cm 0cm 0.0001pt">
        Phone: <a href="tel:1300 207 365">1300 207 365</a>
      </p>
      <p style="background: white; margin: 0cm 0cm 0.0001pt">
        Email:&nbsp;<a href="mailto:raaclaims@tmnfatravelinsurance.com.au"
          >raaclaims&#64;tmnfatravelinsurance.com.au</a
        >
      </p>
      <p style="background: white; margin: 0cm 0cm 0.0001pt">&nbsp;</p>
      <p><strong>I have not started my trip</strong></p>
      <p><strong>Can I purchase a new policy for travel to Israel?</strong></p>
      <p>
        We are at this time continuing to issue policies to travel to Israel,
        however, your ability to recover under any such policies may be limited.
        Please contact <a href="tel:08 8202 4346">08 8202 4346</a> to discuss
        your circumstance further.
      </p>
      <p>
        <strong
          >Can I claim for cancellation of flights and accommodation?</strong
        >
      </p>
      <p>
        At first instance we recommend that customers who are due to travel to
        Israel in the coming days or weeks contact their airline or tour
        operator as they may have available options relating to cancellation of
        trips. Many travel suppliers are waiving change fees and/or offering
        refunds or credits for future travel when traveling to Israel.
      </p>
      <p>
        If you are seeking to claim for cancellation of travel we will be
        requesting information from you regarding any refunds or credits
        received from your airline or tour operator. Please ensure you are ready
        to provide this information to us before you commence your claim.
      </p>
      <p>&nbsp;</p>
      <p>
        For more information please contact
        <a href="tel:1300 207 365">1300 207 365</a>.
      </p>
      <p>
        <strong
          >If I decide to reschedule my trip, can I transfer my insurance to a
          later date?</strong
        >
      </p>
      <p>
        Most policies will allow a change of travel dates. Please contact
        <a href="tel:1300 207 365">1300 207 365</a>
        to discuss rescheduling.
      </p>
      <p><strong>Can I get a refund?</strong></p>
      <p>
        Depending on your circumstances you may be entitled to a full or partial
        refund. Please call <a href="tel:08 8202 4346">08 8202 4346</a> to
        discuss.
      </p>
      <p>
        <strong
          >Will I be covered by my policy if I travel to Israel despite the
          current situation?</strong
        >
      </p>
      <p style="margin-top: 0cm">
        If you purchased a policy prior to 7 October and chose to travel to
        Israel, you should understand that any coverage you have does not extend
        to any claims arising from or relating to any act of war.&nbsp; In
        addition, as the situation is evolving there may be further extensions
        of the DNT warnings to wider areas of Israel. For any questions you have
        in relation to your existing cover please contact
        <a href="tel:08 8202 4346">08 8202 4346</a>.
      </p>
      <p style="line-height: normal">
        <strong>Where can I find other updates and information?</strong>
      </p>
      <p style="line-height: normal">
        Stay up to date on travel information from the Department of Foreign
        Affairs and Trade (DFAT)&nbsp;<a
          href="https://www.smartraveller.gov.au/"
          >Smartraveller</a
        >&nbsp;website.
      </p>

    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
